/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
.fa-refresh{cursor:pointer}

.close:focus {    
    outline: none;
}

.circle-status{
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;         
}

.circle-status.ready, .circle-status.accepted{
    background-color: #00C851;
}

.circle-status.requested{
    background-color: #FFBB34
}

.circle-status.notReady{
    background-color: #FF3547;
}

.borderless td, .borderless th {
    border: none;
}

.progress {    
    height: 15px;
    font-size:10px
}

.level-1 .progress-bar{background-color: #2cd07e;}
.level-2 .progress-bar{background-color: #FDE950;}
.level-3 .progress-bar{background-color: #ffc36d;}
.level-4 .progress-bar{background-color: #ff5050;}

.ind{display: inline;} 
.ind-per{font-weight: 700;float: right;}

.progress{margin-top: 10px;}

:focus {
    outline: none !important;
}

.modal-footer {    
    justify-content: space-between;
}

tr.selectable{
    cursor: pointer;
}
tr.selectable:hover, tr.select{
    background: #FEF7FF;
}

.field-error{color:red; margin-top: 5px;}

.alert-danger {
    border-radius: 0;
}

.no-data h5{font-size: 14px;}

.color-picker .saturation-lightness {
    display:none;
}

.color-picker .hue-alpha {
    display:none !important;
}

.color-picker .hex-text {
    
    padding-top: 10px !important;
    
}

.color-picker .type-policy {display:none}

.btn-primary {
    color: #fff;
    background-color: #00AEEF;
    border-color: #00AEEF;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #00AEEF;
    border-color: #00AEEF;
}

.btn-primary:active, .btn-primary:hover, .btn-primary:focus{
    background: #278CE2;
    border-color:#278CE2;
}

.form-control.form-control-inline{ width:auto;display: inline-block;}

.NgxEditor__Wrapper{
    min-height: 200px;
}

tr {
    cursor: default;
}

@media (min-width: 576px){
    .setup-rules-modal .modal-dialog {
        max-width: 90%;
    }

    .template-modal .modal-dialog {
        max-width: 710px;
    }
}

@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 95%;
    }
}

.page-title{
    margin-bottom: 25px;
}

@import "~jsoneditor/dist/jsoneditor.min.css";
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-h5, .mat-typography h5 {
    font-size: 1.25rem;
}

.mat-h2, .mat-title, .mat-typography h2 {
    font-size: 1.75rem;
}